<template>

  <div class="text" v-if="is_show">
    请点击右上角菜单，选择使用浏览器打开<br>
    (如已支付请返回)
  </div>

</template>

<script>

import { payMix } from "@/api/fkjlb"
import { Base64 } from 'js-base64'
import { isWechat } from "@/utils"
import { Toast } from 'vant'

export default {
  data() {
    return {
      is_show: true
    }
  },
  created() {

    if (this.$route.query.key) {
      console.log(this.$route.query.key)
      const order = JSON.parse(Base64.decode(this.$route.query.key))
      console.log(order)
      this.is_show = isWechat() && (parseInt(order.pay_type) === 1)
      if (!this.is_show) {
        this.pay(order)
      }
    }
  },
  methods: {
    pay(order) {
      Toast.loading({
        message: '发起支付中...',
        forbidClick: true,
        duration: 3000
      })
      payMix(order).then(res => {
        // console.log('res:123456789')
        // console.log(res)
        // console.log('res:456789000')
        // console.log(res.url)
        // return false
        const divForm = document.getElementsByTagName('payForm')
        if (divForm.length) {
          document.body.removeChild(divForm[0])
        }
        const div = document.createElement('payForm')
        // div.innerHTML = res.form // 这个res.form就是后台返回的一串from表单
        div.innerHTML = res.url // 这个res.form就是后台返回的一串from表单
        document.body.appendChild(div)
        // document.forms[0].setAttribute('target', '_blank') // 新开窗口跳转
        document.forms[0].submit()
      })
    }
  }
}
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0px;
}
.text {
  width:100%;
  height:200px;
  text-align:center;
  /* line-height: 200px; */
  margin-top: 50px;
  font-size: 0.16rem;
}

</style>
